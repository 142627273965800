<template>
    <div>
      <b-sidebar ref="sideBarNote" id="sidebar-note" backdrop bg-variant="white" no-header right shadow sidebar-class="sidebar-lg" no-close-on-backdrop>
        <template #default="{ hide }">
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouvelle note</h5>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
          <validation-observer ref="NoteValidations">
            <b-form ref="addNoteForm" autocomplete="off" class="p-2" @submit.prevent="addNote">
              <b-overlay :show="showLoading" no-wrap/>
              <b-form-group label="Note" label-for="note">
                <validation-provider #default="{ errors }" name="Note" rules="required">
                  <quill-editor id="note" v-model="note.texte" :options="snowOption" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
  
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" type="submit" variant="primary"> Enregistrer </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
  
      <b-row>
        <b-col>
          <b-card class="bg-light-primary">
            <p class="mb-0">Article 8 RGPD: il est interdit de collecter ou traiter des données à caractère personnel qui font apparaitre, directement ou indirectement des origines raciales ou ethnique, les opinions politiques, philosophiques ou religieuses ou l'appartenance syndicale des personnes ou qui sont relatives à la santé ou la vie sexuelle de celles-ci.</p>
          </b-card>
        </b-col>
      </b-row>
      <b-overlay :show="showLoading" no-wrap/>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-1 mt-1">
          <b-button v-b-toggle.sidebar-note class="ml-1" size="md" variant="primary" @click="showAddNote">
            <feather-icon icon="PlusIcon" />
            <span class="align-middle text-uppercase"> Ajouter une note interne</span>
          </b-button>
        </div>
      </div>
      <b-list-group class="notes_list">
        <template v-if="notes.length > 0">
          <b-list-group-item v-for="(note, index) in notes" :key="index">
            <b-row class="mb-1">
              <b-col cols="8"><h6 class="font-weight-bolder">{{ note.createdByname }}</h6></b-col>
              <b-col cols="4" class="text-right">
                  <span @click="deleteNote(note.id)" style="cursor:pointer;"><feather-icon size="20" icon="TrashIcon"/></span>
                  <span  v-b-toggle.sidebar-note @click="editNote(note)" style="cursor:pointer;"><feather-icon style="cursor:ponter;" size="20" icon="EditIcon"/></span>
              </b-col>
            </b-row>
            <p v-html="note.texte"></p>
            <small class="font-weight-bolder">{{ note.createdAt }}</small>
          </b-list-group-item>
        </template>
        <template v-else>
          <b-list-group-item class="text-center pt-3 pb-3">
            <h6 class="font-weight-bolder">Aucune note disponible</h6>
          </b-list-group-item>
        </template>
      </b-list-group>
    </div>
  </template>
  <script>
  import { BOverlay, BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BListGroup, BListGroupItem, BRow, BSidebar, VBToggle } from 'bootstrap-vue'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import { quillEditor } from 'vue-quill-editor'
  
  export default {
    components: {
      quillEditor,
      BOverlay,
      BRow,
      BCol,
      BButton,
      BListGroup,
      BListGroupItem,
      BForm,
      BFormGroup,
      BFormInput,
      BSidebar,
      VBToggle,
      Ripple,
      ValidationProvider,
      ValidationObserver,
      BFormTextarea,
      BCard
    },
    props: {
      sinistre: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentUser: JSON.parse(localStorage.getItem('userData')),
        showLoading: false,
        notes: [],
        note: {
          id: null,
          texte: null,
          sinistre_id:this.sinistre.id,
          sinistrable_type:this.sinistre.sinistrable_type,
          sinistrable_id:this.sinistre.sinistrable_id,
          userId: null
        },
        snowOption: {
          theme: 'snow',
          placeholder: 'Écrivez une note...'
        },
      }
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    mounted() {
      // this.fetchNotesByTier()
      this.note.userId = this.currentUser.courtier_user[0].user_id
      
    },
    watch: {
      
    },
    created(){
      this.fetchNotesBySinistre()
    },
    methods: {
      fetchNotesBySinistre(){
        this.showLoading = true
        this.notes = []
        this.$http
            .get(`/note/fetchNotesBySinistre/${this.sinistre.id}`)
            .then(res => {
              if (res.data.success) {
                this.notes = res.data.data
                this.$emit('set-notes-count',this.notes.length);
                this.showLoading = false
              }
            })
            .catch(err => {
              this.showLoading = false
              console.error(err)
            })
      },
      addNote() {
        this.$refs.NoteValidations.validate().then(success => {
          if (success) {
            console.log(this.note)
            this.showLoading = true
            this.$http
              .post('/note/actionNewNoteSinistre', this.note)
              .then(r => {
                console.log(r.data.success)
                if (r.data.success) {
                  // if (r.data.data.isCreated) {
                    this.$refs.sideBarNote.hide();
                    this.note.id = null
                    this.note.texte = null
                    this.$refs.NoteValidations.reset()
                    this.fetchNotesBySinistre()
                    this.messageToast(r.data.message, "Succès", "success");
                    this.showLoading = false
                  // }
                  // else
                  // {
                  //   this.$refs.sideBarNote.hide();
                  //   this.$refs.NoteValidations.reset()
                  //   this.notes.forEach((note, index)=>{
                  //     if(note.id == this.note.id)
                  //     {
                  //       note.texte = this.note.texte
                  //     }
                  //   })
                  //   this.note.id = null
                  //   this.note.texte = null
                  //   this.showLoading = false
                    
                  // }
                }
              })
              .catch(err => {
                this.showLoading = false
                this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
                console.log(err)
              })
          }
        })
      },
      showAddNote(){
        this.note.id = null
        this.note.texte = null
      },
      editNote(note){
        this.note.id = note.id
        this.note.texte = note.texte
      },
      deleteNote(id) {
        this.$swal({
          title: 'Etes-vous sûr de vouloir supprimer cette note ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.$http
              .delete(`note/deleteNote/${id}`)
              .then(res => {
                if(res.data.success){
                  // this.notes.forEach((note, index)=>{
                  //     if(note.id == id)
                  //     {
                  //       this.notes.splice(index, 1)
                  //     }
                  //   })
                  this.fetchNotesBySinistre();

                }
                this.messageToast(res.data.message, 'Succès', 'success')
              })
              .catch(error => {
                console.log(error)
                this.messageToast(error.response.data, 'Erreur', "danger")
              })
          }
        })
      },
    }
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';
  </style>
  <style scoped>
  .notes_list {
    margin-bottom: 1rem;
  }
  
  .notes_list.list-group .list-group-item:nth-child(even) {
    background-color: #f4f7fc;
  }
  
  .notes_list svg {
    float: right;
  }
  </style>
  