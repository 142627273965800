<template>
  <div>
     <actions-sinistre 
        :interface-chosen="interfaceChosen"
        :gestionnaire-options="gestionnaireOptions" 
        :assureur-options="assureurOptions" 
        :risque-options="risqueOptions" 
        :current-user="currentUser"  
        :sinistre-for-edit="sinistreForEdit"
        :is-update.sync="isUpdate"
        :prospect-options="prospectData"
        @fetch-data-ref="$emit('fetch-data-ref',$event)"
        :projet-contrat-data="projetContratData"
        @refresh-sinistre="$emit('refresh-sinistre',sinistre.id)"
         /> 
   <b-card class="mb-2">
     <b-card-text>
       <b-row>
         <b-col md="12" sm="12">
           <div class="d-flex bd-highlight mb-2">
             <div class="bd-highlight text-uppercase h3 font-weight-bold">
               <b>{{ sinistre.risque }} - {{ sinistre.assureur }}</b>
             </div>
             <div class="ml-auto bd-highlight">
               <div class="demo-inline-spacing">
                 <b-button @click="$emit('edit-sinistre')"
                   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                   variant="primary"
                   size="sm"
                   class="mt-0"
                   v-b-toggle.sidebar-sinistre
                   
                 >
                   <feather-icon icon="EditIcon" class="mr-50"/>
                   <span class="align-middle text-capitalize">Modifier</span>
                 </b-button>

                 <b-button v-if="sinistre.statut != 'CLOS' || sinistre.statut == 'EN_COURS'"
                   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                   variant="primary"
                   size="sm"
                   class="mt-0"
                   @click="clotureSinistre(sinistre)"
                 >
                   <feather-icon icon="ArchiveIcon" class="mr-50" />
                   <span class="align-middle text-capitalize">Clôturer</span>
                 </b-button>

                 <b-button v-if="sinistre.statut != 'REOUVERT' && sinistre.statut != 'EN_COURS'"
                   v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                   variant="primary"
                   size="sm"
                   class="mt-0"
                   @click="rouvrir(sinistre.id)"
                 >
                   <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                   <span class="align-middle text-capitalize">Rouvrir</span>
                 </b-button>
                 <b-button
                     v-ripple.400="'rgba(255, 255, 255, 0.15)' || currentUser.courtier_user[0].isPrincipal === 1"
                     :aria-expanded="String(isEventHandlerSidebarActive)"
                     aria-controls="sidebar-add-new-event"
                     size="sm"
                   class="mt-0"

                     variant="primary"
                     @click="isEventHandlerSidebarActive = true"
                 >
                   <feather-icon
                       icon="PlusIcon"
                       class="mr-50"
                     />
                   <span class="align-middle text-capitalize">Ajouter un évènement</span>
                 </b-button>
               </div>
             </div>
           </div>
         </b-col>

         <b-col cols="12">
           <b-row class="mb-4">
             <b-col cols="12" xl="6">
               <table class="mt-2 mt-xl-0 w-100">
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Date de déclaration :
                     </span>
                   </th>
                   <td class="pb-50 text-capitalize">
                     {{ sinistre.date_declaration }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Date de sinistre :
                     </span>
                   </th>
                   <td class="pb-50 text-capitalize">
                     {{ sinistre.date_sinistre }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Date de clôture :
                     </span>
                   </th>
                   <td class="pb-50 text-capitalize">
                     {{ sinistre.date_cloture }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Montant du sinistre :
                     </span>
                   </th>
                   <td class="pb-50 text-capitalize">
                     {{ sinistre.montant_sinistre + '€' }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder">Type de sinistre : </span>
                   </th>
                   <td class="pb-50">
                     {{ mappingOfSinistreType(sinistre.type_sinistre) }}
                   </td>
                 </tr>
               </table>
             </b-col>
             <b-col cols="12" xl="6">
               <table class="mt-2 mt-xl-0 w-100">
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder">Sinistre en délégation : </span>
                   </th>
                   <td class="pb-50 text-uppercase">
                    {{ sinistre.isDelegation == '1' ? 'OUI' : 'NON'  }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder">Responsabilité : </span>
                   </th>
                   <td class="pb-50">{{ sinistre.responsabilite }}</td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder">Coordonnées de l’expert : </span>
                   </th>
                   <td class="pb-50">{{ sinistre.expert }}</td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder">Client : </span>
                   </th>
                   <td class="pb-50">  
                    <span v-if=" sinistre.sinistre_client != ''" @click=" showProspect(sinistre.sinistre_client.id,sinistre.sinistre_client.type)">
                      <a style="text-decoration: underline">{{ sinistre.client }}</a>
                    </span>  
                    <span v-else>
                      {{ sinistre.nom_sinistre + ' ' + sinistre.prenom_sinistre }}
                    </span>  
                  </td>
                 </tr>
                 <tr> 
                   <th class="pb-50">
                     <span class="font-weight-bolder">Contrat : </span>
                   </th>
                   <td>
                    <span v-if="sinistre.sinistre_reference != null" @click="showContrat(sinistre.contrat_id, sinistre.contrat_source)">     
                      <a style="text-decoration: underline;">{{ sinistre.contrat_no }}</a>             
                    </span>
                    <span v-else>     
                      {{ sinistre.contrat_no }}         
                    </span>
                   </td>
                 </tr>
               </table>
             </b-col>
           </b-row>

           <b-row>
             <b-col cols="6" class="details">
               <h3
                 class="
                   text-primary text-decoration-underline
                   font-weight-bolder
                   mb-1
                 "
               >
                 <u>Détails</u>
               </h3>
               
               <!--  -->
               <b-row>
                 <b-col cols="8" class="d-flex align-items-center">
                   <div
                     style=" min-height: 100px;flex: 2;padding: 5px;border: 1px solid #4d25bc; width: 100%;"
                   >
                     <p class="w-100" style="">
                       <strong>Commentaire : </strong
                       ><span v-html="sinistre.comment"></span>
                     </p>
                   </div>
                   <b-button
                     v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                     class="btn-icon rounded-circle"
                     variant="flat-primary"
                     v-b-modal.modal_commentaire
                   >
                     <feather-icon icon="Edit3Icon" />
                   </b-button>
                 </b-col>
               </b-row>
             </b-col>
             <!-- <b-col cols="6" v-if="sinistre.issue" class="issue">
               <h3
                 class="
                   text-primary text-decoration-underline
                   font-weight-bolder
                   mb-1
                 "
               >
                 <u>Issue :</u>
                 <b-badge
                   style="font-size: 11.9px; margin-left: 7px"
                   variant="light-warning"
                   >{{ sinistre.issue }}</b-badge
                 >
               </h3>
               <table class="mt-2 mt-xl-0 w-100">
                 <tr>
                   <th  class="pb-50">
                     <span class="font-weight-bolder">Date de clôture : </span>
                   </th>
                   <td class="pb-50 text-uppercase">
                     {{ sinistre.date_cloture }}
                   </td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Réponse apportée :
                     </span>
                   </th>
                   <td class="pb-50" v-html="sinistre.reponse_app"></td>
                 </tr>
                 <tr>
                   <th class="pb-50">
                     <span class="font-weight-bolder"
                       >Mesures correctives :
                     </span>
                   </th>
                   <td class="pb-50" v-html="sinistre.mesure_cor"></td>
                 </tr>
               </table>
             </b-col> -->
           </b-row>
         </b-col>
       </b-row>
     </b-card-text>
     <!-- basic modal -->
     <b-modal
       id="modal_commentaire"
       hide-footer
       centered
       title="Modifier Le Commentaire"
       :show="showModalLoading"
     >
       <b-overlay :show="showModalLoading" no-wrap />
       <validation-observer ref="formUpdateCommentaire">
         <b-form class="p-2" @submit.prevent="updateCommentaire">
           <b-form-group label="Commentaire*" label-for="commentaire">
             <div class="commentaire">
               <validation-provider
                 #default="{ errors }"
                 name="commentaire"
                 rules="required"
               >
                 <quill-editor
                   v-model="comment_keep"
                   id="commentaire"
                   :options="editorOptions('commentaire')"
                   :class="errors.length > 0 ? 'is-invalid' : ''"
                 />
                 <b-form-invalid-feedback
                   :state="errors.length > 0 ? false : null"
                   >{{ errors[0] }}
                 </b-form-invalid-feedback>
               </validation-provider>
             </div>
           </b-form-group>
           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Modifier
             </b-button>
           </div>
         </b-form>
       </validation-observer>
     </b-modal>
   </b-card>
   <calendar-event-handler v-model="isEventHandlerSidebarActive" :event="event" :clear-event-data="clearEventData" @add-event="addEvent" :prospect-selected="tiersData"  />
 </div>
 </template>
 
 <script>
 import {
   BBadge,
   BButton,
   BCard,
   BCardText,
   BCol,
   BRow,
   VBToggle,
   BFormInvalidFeedback,
   BFormTextarea,
   BFormGroup,
   BForm,
   BOverlay,
   BModal,
   VBPopover,
 } from "bootstrap-vue";
 import FullCalendar from '@fullcalendar/vue'
import actionsSinistre from '../actions-sinistre.vue'
 
 import Ripple from "vue-ripple-directive";
 import moment from "moment";
 import { formatDate } from "@core/utils/filter";
 import { ValidationProvider, ValidationObserver } from "vee-validate";
 import { quillEditor } from "vue-quill-editor";
 // import ActionsReclamations from '../ActionsReclamations'
 import store from '@/store'
 import { onUnmounted } from '@vue/composition-api'
 import calendarStoreModule from '../../../calendries/calendarStoreModule'
 import CalendarEventHandler from '../../../calendries/calendar-event-handler/CalendarEventHandler.vue'
 import useCalendar from '../../../calendries/useCalendar'
 export default {
   components: {
     FullCalendar,
     CalendarEventHandler,
     BBadge,
     BRow,
     BCol,
     BButton,
     BCard,
     BCardText,
     ValidationProvider,
     ValidationObserver,
     BFormInvalidFeedback,
     BFormTextarea,
     BFormGroup,
     BForm,
     BOverlay,
     BModal,
     quillEditor,
      actionsSinistre
   },
   directives: {
     Ripple,
     "b-toggle": VBToggle,
     'b-popover' : VBPopover,
   },
   props: {
     sinistre: {
       type: Object,
       required: true,
     },
     // reclamationForEdit: {
     //     type: Object,
     //     required:true
     //   },
     interfaceChosen: {
         type: String,
         default: "Listing",
         required: false
       },
      //  isUpdate: {
      //    type: Boolean,
      //    default: true,
      //    required: true
      //  },
      risqueOptions : {
      type: Array,
      default: [],
      required: true
    },
    assureurOptions : {
      type: Array,
      default: [],
      required: true
    },
    gestionnaireOptions:{
      type: Array,
      default: [],
      required: true
    },
    projetContratData: {
        type: Array,
        default: [],
        required: false
      },
    sinistreForEdit: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        id: null,
              numero_sinistre: null,
              canal_sinistre : null,
              date_acc_recep : null,
              is_client:false,
              sinistree: {
                id: null,
                type: null,
                categorie: null,
                client: null,
                nom: null,
                prenom: null
              },
                reference: {
                id: null,
                numero: null,
                source: null,
                type: null,
                ref_externe: null,
                assureur_id:null,
                risque_id:null
              },
              risque_id : null,
              assureur_id : null,
              courtier_id: null,
              date_sinistre: null,
              type : null,
              statut: null,
              responsabilite : null,
              date_declaration: null,
              montant : null,
              isDelegation: 0,
              expert : null,
              date_cloture : null,
              gestionnaire : null,
      
      required: false
      }
    },
    currentUser: {
      type: Object,
      default: null,
      required: false
    },
    prospectData: { 
        type: Array,
        default: [],
        required: true,
      },
    
   },
   data() {
     return {
       showModalLoading: false,
       reference:null,
       comment_keep: null,
       snowOption: {
         theme: "snow",
         placeholder: {
           commentaire: "Ecrivez votre commentaire…",
           reponse: "Ecrivez la réponse apportée…",
           mesure: "Ecrivez les mesures correctives…",
         },
         modules: {
           toolbar: ["bold", "italic", "underline", "strike"],
         },
       },
     };
   },
  
   mounted() {
     this.inialeCommentKeep();

     // this.reference = this.sinistre.reclamation_reference;
   },
   methods: {
     // showProjetContrat(id, type, inter) {
     //   if (inter == 'FicheContrat') {
     //      this.$router.push({
     //       name: 'contrats-list',
     //       params: {
     //         'id': id,
     //         'type': type,
     //         'interfaceContrat': inter,
     //       }
     //     })  
     //   }
     //   else {
     //     this.$router.push({
     //       name: 'projets-list',
     //       params: {
     //         'id': id,
     //         'type': type,
     //         'interfaceChosen': inter
     //       }
     //     })
 
     //   }
      
     // },
  
     // recordReclamationReturned(){
     //   this.$emit('record-sinistre-returned');
 
     // },
     inialeCommentKeep() {
       this.comment_keep = this.sinistre.comment;
     },
     editorOptions(fieldName) {
       return {
         theme: this.snowOption.theme,
         placeholder: this.snowOption.placeholder[fieldName],
         modules: this.snowOption.modules,
       };
     },
     mappingOfSinistreType(type){
      switch (type) {
        case 'DOMMAGE_CORPOREL':
          return 'Dommage corporel'
        case 'DOMMAGE_MATERIEL':
          return 'Dommage matériel'
        case 'DOMMAGE_CORPOREL_ET_MATERIEL':
          return 'Dommage corporel et matériel'
        default:
          return '';
      }
     },
     
     updateCommentaire() {
       this.$refs.formUpdateCommentaire.validate().then((success) => {
         if (success) {
           this.showModalLoading = true;
           this.$http
             .post("sinistre/editCommentSinistre", {
               sinistreId: this.sinistre.id,
               comment: this.comment_keep,
             })
             .then((response) => {
               console.log(response.data.data.comment);
               if (response.data.success) {
                 this.sinistre.comment = response.data.data.comment;
                 this.inialeCommentKeep();
                 this.messageToast(response.data.message, "Succès", "success");
               } else {
                 this.messageToast(response.data.message, "Erreur", "danger");
               }
               this.showModalLoading = false;
               this.$bvModal.hide("modal_commentaire");
             })
             .catch((err) => {
               this.messageToast(err.message, "Erreur", "danger");
               this.showModalLoading = false;
             });
         }
       });
     },
     showProspect(id, type) {
      if (type == "PERSONNE_PHYSIQUE") {
        this.$router.push({
          name: "tiers-list",
          params: {
            id: id,
            type: type,
            interfaceChosen: "Professionnel",
          },
        });
      } else {
        this.$router.push({
          name: "tiers-list",
          params: {
            id: id,
            type: "PERSONNE_MORALE",
            interfaceChosen: "Entreprise",
          },
        });
      }
    },
    showContrat(idContrat, typeContrat) {
      this.$router.push({
        name: 'contrats-list',
        params: {
          'id': idContrat,
          'type': typeContrat,
          'interfaceContrat': 'FicheContrat'
        }
      })
    },
    clotureSinistre(row) {
        this.$swal({
          title: 'Etes-vous sûr de vouloir clôturer ce sinistre ?',
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.showLoading = true;
            this.$http
              .post("/sinistre/cloture", {
                data: row,
              })
              .then((res) => {
                if (res.data.success) {
                  this.showLoading = false;
                  this.$emit('refresh-sinistre',row.id)
                  this.$emit('refresh-sinistre-list')
                  this.messageToast(
                    "le sinistre est clôturer",
                    "Succès",
                    "success"
                  );
                }
              })
              .catch((err) => {
                this.showLoading = false;
                console.error(err);
              });
          }
        });

    },
     rouvrir(id){
         this.$swal({
         title: 'Etes-vous sûr de vouloir rouvrir ce sinistre ?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Oui',
         cancelButtonText: 'Non',
         customClass: {
           confirmButton: 'btn btn-primary',
           cancelButton: 'btn btn-outline-danger ml-1'
         },
         buttonsStyling: false
         }).then(result => {
               if (result.value) {
                 this.showLoading = true
                 this.$http
                   .post('/sinistre/updateSinistreStatus', {
                     id: id,
                    })
                   .then(res => {
                     if (res.data.success) {
                       this.showLoading = false
                      this.$emit('refresh-sinistre',id)
                      this.$emit('refresh-sinistre-list')
                      // this.$emit('back-to-listing');
                       this.messageToast('Le sinistre a été réouvert', 'Succès', 'success')
                     
                     }
                   })
                   .catch(err => {
                     this.showLoading = false
                     console.error(err)
                   })
           
                 }
               })
       },
   },
   setup() {
      const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
     // Register module
     if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
     // UnRegister on leave
     onUnmounted(() => {
       if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
     })
 
     const {
       event,
       clearEventData,
       addEvent,
       calendarOptions,
       // ----- UI ----- //
       isEventHandlerSidebarActive
     } = useCalendar()
 
     return {
       event,
       clearEventData,
       addEvent,
       calendarOptions,
       // ----- UI ----- //
       isEventHandlerSidebarActive
     }
   }
 };
 </script>
 <style>
 .button-Commentaire {
   right: -37px;
   top: 0;
   height: 24px;
   width: 24px;
   padding: 3px !important;
 }
 .issue p{
     margin: 0 !important;
 }
 .details p{
   margin: 0 !important;
 }
 </style>