<template>
  <div>
    <b-overlay :show="showLoading" no-wrap/>
    <b-row>
      <b-col md="6" sm="12">
        <span class="h1 text-primary font-weight-bolder ml-1 text-uppercase"> 
          Fiche Sinistre - {{ sinistre.numero_sinistre }}
          <span></span>
        </span>
        <b-badge variant="light-warning">{{ mappingOfSinistreStatus(sinistre.statut) }}</b-badge>
      </b-col>
      <b-col md="6" sm="12">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="text-uppercase float-md-right"
            size="md"
            variant="primary"
          @click="backToListing"
            id="revenir-liste-sinistres"
        >
          <feather-icon icon="ArrowLeftIcon"/>
          <span class="align-middle"> revenir à la liste</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col md="12" sm="12">
        <card-information @refresh-sinistre-list="$emit('refresh-sinistre-list')" 
        @refresh-sinistre="fetchSinistreById" 
        :sinistre="sinistre" @back-to-listing="backToListing" 
        :interface-chosen="interfaceSinistre"
        :gestionnaire-options="gestionnaireOptions" 
        :assureur-options="assureurOptions" 
        :risque-options="risqueOptions"
        :prospect-data="prospectOptions"
        :current-user="currentUser"    
        :is-update.sync="isUpdate"
        :sinistre-for-edit="sinistreForEdit"
        :projet-contrat-data="projetContratData"
        @fetch-data-ref="fetchDataRef"
        @edit-sinistre="$emit('edit-sinistre', sinistre)"
        @record-sinistre-returned="recordSinistreReturned"
        />
                
      </b-col>
      <b-col md="12" sm="12">
        <b-card class="mb-2">
          <tabs-details-fiche-sinistre :sinistre="sinistre" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBadge, BButton, BCard, BCardText, BCol, BOverlay, BRow,VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CardInformation from '@/views/portefeuilles/sinistres/detail_fiche_sinistre/CardInformation.vue'
import TabsDetailsFicheSinistre from './TabsDetailsFicheSinistre.vue'

export default {
  components: {
    // UI
    // BTV
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    VBToggle,
    //Ui
    CardInformation,
    TabsDetailsFicheSinistre,

  },
  directives: { Ripple },
  props: {
    sinistreChosen: {
    type: Object,
    default: {
      id: null,
      type: null
    },
    required: true,
  },
  risqueOptions : {
      type: Array,
      default: [],
      required: true
    },
    assureurOptions : {
      type: Array,
      default: [],
      required: true
    },
    gestionnaireOptions:{
      type: Array,
      default: [],
      required: true
    },
    sinistreForEdit: {
      type: Object,
      default: {
        id: null,
              numero_sinistre: null,
              canal_sinistre : null,
              date_acc_recep : null,
              is_client:false,
              sinistree: {
                id: null,
                type: null,
                categorie: null,
                client: null,
                nom: null,
                prenom: null
              },
                reference: {
                id: null,
                numero: null,
                source: null,
                type: null,
                ref_externe: null,
                assureur_id:null,
                risque_id:null
              },
              risque_id : null,
              assureur_id : null,
              courtier_id: null,
              date_sinistre: null,
              type : null,
              statut: null,
              responsabilite : null,
              date_declaration: null,
              montant : null,
              isDelegation: 0,
              expert : null,
              date_cloture : null,
              gestionnaire : null,
      
      required: false
      }
    },
    currentUser: {
      type: Object,
      default: null,
      required: false
    },
    prospectOptions: {
        type: Array,
        default: [],
        required: true,
      },
    
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      showLoading: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      sinistre:null,
    interfaceSinistre: 'FicheSinistre',
    prospectData: [],
    projetContratData: [],
    prospectChosen: {
        id: null,
        type: null
      },
    isUpdate: true,

      
    }
  },
  created(){
    
    this.fetchSinistreById(this.sinistreChosen.id)
    this.fetchProspectData()
  },

  watch: {
    
  },
  methods: {
    backToListing() {
      this.$emit('back-to-listing', 'Listing')
    },
    fetchSinistreById(id) {
      this.$http.get(`sinistre/fetch-sinistre-by-id/${id}`).then((res)=>{
        console.log(res.data)
        this.sinistre = res.data.data[0];
      })    
    },

    fetchProspectData() {
      this.$http.get('/prospect/getAllProspect')
        .then(r => {
          this.pushAndFusiondata(r.data[0].concat(r.data[1]))
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    pushAndFusiondata(array) {
      this.prospect_filtred = []
      array.forEach(item => {
        
        this.prospect_filtred.push({
          id: item.id,
          client: item.type === 'PERSONNE_MORALE' ? item.denomination_commercial : item.nom + ' ' + item.prenom,
          nom: null,
          prenom: null,
          categorie: item.categorie,
          type: item.type
        })
      })
      this.prospectData = this.$_.orderBy(this.prospect_filtred, ['type'], ['desc'])
    },



     fetchContratsList() {
      this.$http
          .post('/contrat/fetchDataContrats', {
            courtier_user_id: this.currentUser.courtier_user[0].id,
            user_id: this.currentUser.courtier_user[0].user_id,
            courtier_id: this.currentUser.courtier_user[0].courtier_id,
            clientId: this.prospectChosen.id,
            clientType:this.prospectChosen.type
          })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(element => {
                this.projetContratData.push({
                  id: element.id,
                  numero: element.police_no ? element.police_no : 'NC',
                  source: element.source,
                  type: 'CONTRAT',
                  ref_externe: null,
                  assureur_id:element.assureur_id,
                  risque_id:element.risque_id
                })
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
    },
 
    recordSinistreReturned(sinistre, message) {
        this.messageToast(message, 'Succès', 'success')
    },
    mappingOfSinistreStatus(status){
      switch (status) {
        case 'EN_COURS':
          return 'En cours'
        case 'CLOS':
          return 'Clos'
        case 'REOUVERT':
          return 'Réouvert'
        default:
          return '';
      }
     },
 
     fetchDataRef(prospect) {
       this.projetContratData = []
       this.prospectChosen.id = prospect.id
       this.prospectChosen.type = prospect.type
       this.fetchContratsList()
     }
     
  }
}
</script>