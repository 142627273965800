<template>
    <b-tabs content-class="pt-2" justified>
      <b-tab :title="'Notes interne (' + notesCount + ')'">
        <b-card-text>
          <notes-internes :sinistre="sinistre" @set-notes-count="setNotesCount" />
        </b-card-text>
      </b-tab>
      
      <b-tab :title="'Documents (' + documentsCount + ')'">
        <b-card-text>
          <documents :sinistre="sinistre" @set-documents-count="setDocumentsCount" />
        </b-card-text>
      </b-tab>
     
    </b-tabs>
  </template>
  
  <script>
  import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue'
  import NotesInternes from '@/views/portefeuilles/sinistres/detail_fiche_sinistre/NotesInternes.vue'
  import Documents from '@/views/portefeuilles/sinistres/detail_fiche_sinistre/Document.vue'
  export default {
    components: {
      BTabs,
      BTab,
      BCardText,
      BButton,
      NotesInternes,
      Documents
      
    },
    props: {
      sinistre: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        notesCount:0,
        documentsCount:0,
        }
      }
    ,
    methods: {
      setNotesCount(val){
        console.log(val)
        this.notesCount = val
      },
      setDocumentsCount(val){
        this.documentsCount = val
      },
    },

  }
  </script>
  